.education {
    margin-top: 0px;
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding: 30px 20%;
    background-color: #eeeeee;
}

.sectionTitle {
    margin-top: 10px;
    font-size: 32px;
    font-weight: 800;
    color: #333;
}

.educationCardWrapper {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between; /* creates equal distance between cards */
    width: 100%;
    background-color: transparent;
}

.educationCard {
    width: 30%; /* adjust width as needed */
    height: 250px; /* adjust height as needed */
    background-color: #5727B0;
    border-radius: 15px;
    /* border: 1px solid #ccc; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
}

.educationCardTitle {
    font-size: 28px;
    font-weight: 800;
    /* padding-top: 10px; */
}

.educationCardSubtitle {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
}

/* CSS rules for iPhones in portrait orientation */
@media only screen and (min-width: 320px) and (max-width: 500px) and (orientation: portrait) {
    .educationCardWrapper {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .educationCard {
        width: 100%; /* adjust width as needed */
        height: 200px; /* adjust height as needed */
        margin-bottom: 10px; 
    }

    .educationCardTitle {
        font-size: 20px;
        font-weight: 800;
        /* padding-top: 10px; */
    }
    
    .educationCardSubtitle {
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 10px;
    }
}

/* CSS rules for screens between the widths of the iPad mini and 12.9 inch iPad Pro in portrait mode go here */
@media only screen and (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
    .educationCardWrapper {
        width: 133%;
    }

    .educationCardTitle {
        font-size: 24px;
        font-weight: 800;
        /* padding-top: 10px; */
    }
    
    .educationCardSubtitle {
        font-size: 18px;
        font-weight: 400;
        padding-bottom: 10px;
    }
}

/* CSS rules for iPhones in landscape orientation */
@media only screen and (min-width: 568px) and (max-width: 736px) and (orientation: landscape) {
    .educationCardWrapper {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .educationCard {
        width: 100%; /* adjust width as needed */
        height: 200px; /* adjust height as needed */
        margin-bottom: 10px; 
    }

    .educationCardTitle {
        font-size: 24px;
        font-weight: 800;
        /* padding-top: 10px; */
    }
    
    .educationCardSubtitle {
        font-size: 20px;
        font-weight: 400;
        padding-bottom: 10px;
    }
}