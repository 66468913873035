.codingProjectCard {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    height: 400px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;
}
    
.codingProjectCard img {
    height: 100%;
    width: auto;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}
    
.codingProjectCard__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 24px;
}
    
.codingProjectCard__title {
    text-align: left;
    font-size: 36px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
}
    
.codingProjectCard__description {
    font-size: 24px;
    color: #333;
    text-align: left;
    font-weight: 300;
    margin-bottom: 20px;
}

.codingProjectCard__button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #ECEFF1;;
    background-color: rgba(87, 39, 176, 1);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    padding: 7.5px 10px;
    width: fit-content;
}

/* CSS rules for iPhones in portrait orientation */
@media only screen and (min-width: 320px) and (max-width: 500px) and (orientation: portrait) {
    .codingProjectCard {
        width: 133%;
        flex-direction: column;
        height: fit-content;
    }

    .codingProjectCard img {
        height: 0%;
    }

    .codingProjectCard__title {
        text-align: center;
        font-size: 20px;
    }
        
    .codingProjectCard__description {
        font-size: 16px;
        text-align: center;
    }

    .codingProjectCard__button {
        align-self: center;
        font-size: 12px;
        font-weight: 600;
    }
}

/* CSS rules for screens between the widths of the iPad mini and 12.9 inch iPad Pro in portrait mode go here */
@media only screen and (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
    .codingProjectCard {
        width: 150%;
    }

    .codingProjectCard__description {
        font-size: 16px;
    }
}

/* CSS rules for iPhones in landscape orientation */
@media only screen and (min-width: 568px) and (max-width: 736px) and (orientation: landscape) {
    .codingProjectCard {
        flex-direction: column;
        height: fit-content;
    }

    .codingProjectCard img {
        height: 0%;
    }

    .codingProjectCard__title {
        text-align: center;
        font-size: 24px;
    }
        
    .codingProjectCard__description {
        font-size: 20px;
        text-align: center;
    }

    .codingProjectCard__button {
        align-self: center;
        font-size: 12px;
        font-weight: 600;
    }
}

/* CSS rules for iPads in landscape orientation */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
    .codingProjectCard {
        width: 133%;
    }

    .codingProjectCard__description {
        font-size: 20px;
    }
}