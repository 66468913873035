.row {
    width: 60%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center; /* Horizontally center the columns */
    align-items: center; /* Vertically center the columns */
    margin-top: 25px;
    margin-bottom: 25px;
}

.column {
    margin: 10px;
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the contents vertically */
    justify-content: center; /* Center the contents horizontally */
    text-align: center; /* Center the text horizontally */
}

.row .column img {
    width: 100px;
    height: 100px;
    background-color: transparent;
}

.text-container {
    background-color: transparent;
    padding-top: 10px;
}
  
.text-container h2 {
    font-size: 24px;
    font-weight: 600;
    color: black;
}



/* CSS rules for iPhones in portrait orientation */
@media only screen and (min-width: 320px) and (max-width: 500px) and (orientation: portrait) {
    .row {
        width: 50%;
    }
    
    .row .column img {
        width: 75px;
        height: 75px;
    }

    .text-container {
        width: 200px;
        display: flex;
        flex-direction: column;
    }

    .text-container h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        color: black;
    }
}

/* CSS rules for iPhones in landscape orientation */
@media only screen and (min-width: 568px) and (max-width: 736px) and (orientation: landscape) {
    .row {
        width: 50%;
    }
    
    .row .column img {
        width: 75px;
        height: 75px;
    }

    .text-container {
        width: 400px;
        display: flex;
        flex-direction: column;
    }

    .text-container h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        color: black;
    }
}
