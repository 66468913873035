.contact {
    margin-top: 0px;
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding: 30px 20%;
    background-color: #eeeeee;
}

.contactCardWrapper {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between; /* creates equal distance between cards */
    width: 100%;
    background-color: transparent;
}

.contactCard {
    width: 30%; /* adjust width as needed */
    height: 250px; /* adjust height as needed */
    background-color: #5727B0;
    border-radius: 15px;
    /* border: 1px solid #ccc; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
}

.contactCardTitle {
    font-size: 28px;
    font-weight: 800;
    /* padding-top: 10px; */
}

.contactCardSubtitle a {
    text-decoration: underline;
    color: #ffffff;
    background-color: transparent;
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
}

/* CSS rules for iPhones in portrait orientation */
@media only screen and (min-width: 321px) and (max-width: 500px) and (orientation: portrait) {
    .contactCardWrapper {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .contactCard {
        width: 100%; /* adjust width as needed */
        height: 200px; /* adjust height as needed */
        margin-bottom: 10px; 
    }

    .contactCardTitle {
        font-size: 20px;
    }
    
    .contactCardSubtitle a {
        font-size: 16px;
    }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
    .contactCardWrapper {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .contactCard {
        width: 100%; /* adjust width as needed */
        height: 200px; /* adjust height as needed */
        margin-bottom: 10px; 
    }

    .contactCardTitle {
        font-size: 20px;
    }
    
    .contactCardSubtitle a {
        font-size: 14px;
    }
}

/* CSS rules for screens between the widths of the iPad mini and 12.9 inch iPad Pro in portrait mode go here */
@media only screen and (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
    .contactCardWrapper {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .contactCard {
        width: 100%; /* adjust width as needed */
        height: 200px; /* adjust height as needed */
        margin-bottom: 10px; 
    }

    .contactCardTitle {
        font-size: 28px;
    }
    
    .contactCardSubtitle a {
        font-size: 20px;
    }
}

/* CSS rules for iPhones in landscape orientation */
@media only screen and (min-width: 568px) and (max-width: 736px) and (orientation: landscape) {
    .contactCardWrapper {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .contactCard {
        width: 100%; /* adjust width as needed */
        height: 200px; /* adjust height as needed */
        margin-bottom: 10px; 
    }

    .contactCardTitle {
        font-size: 24px;
    }
    
    .contactCardSubtitle a {
        font-size: 20px;
    }
}

/* CSS rules for iPads in landscape orientation */
@media only screen and (min-width: 1023px) and (max-width: 1366px) and (orientation: landscape) {
    .contactCardWrapper {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .contactCard {
        width: 100%; /* adjust width as needed */
        height: 200px; /* adjust height as needed */
        margin-bottom: 10px; 
    }

    .contactCardTitle {
        font-size: 28px;
    }
    
    .contactCardSubtitle a {
        font-size: 20px;
    }
}